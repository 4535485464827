/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import React, { Fragment } from 'react';
import 'styles/index.scss';

export const wrapRootElement = ({ element }) => element;

export const wrapPageElement = ({ element }) => element;
