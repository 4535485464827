exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meteor-first-step-to-upgrade-to-meteor-3-tsx": () => import("./../../../src/pages/meteor/first-step-to-upgrade-to-meteor-3.tsx" /* webpackChunkName: "component---src-pages-meteor-first-step-to-upgrade-to-meteor-3-tsx" */),
  "component---src-pages-meteor-meteor-roadmap-2024-tsx": () => import("./../../../src/pages/meteor/meteor-roadmap-2024.tsx" /* webpackChunkName: "component---src-pages-meteor-meteor-roadmap-2024-tsx" */),
  "component---src-pages-meteor-meteor-v-3-update-service-tsx": () => import("./../../../src/pages/meteor/meteor-v3-update-service.tsx" /* webpackChunkName: "component---src-pages-meteor-meteor-v-3-update-service-tsx" */),
  "component---src-pages-projects-ai-ocr-doc-processing-index-tsx": () => import("./../../../src/pages/projects/ai-ocr-doc-processing/index.tsx" /* webpackChunkName: "component---src-pages-projects-ai-ocr-doc-processing-index-tsx" */),
  "component---src-pages-projects-ai-ocr-doc-processing-structure-and-team-role-tsx": () => import("./../../../src/pages/projects/ai-ocr-doc-processing/structure-and-team-role.tsx" /* webpackChunkName: "component---src-pages-projects-ai-ocr-doc-processing-structure-and-team-role-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */)
}

